import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="drawer"
export default class extends Controller {
  connect() {
    this.offCanvas = new bootstrap.Offcanvas(this.element)
    this.show()
  }

  show() {
    this.offCanvas.show()
  }
  
  hide() {
    this.offCanvas.hide()
    this.element.innerHTML = ""
  }
}
