// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

import 'simplebar';
import '@oddcamp/cocoon-vanilla-js';

import './source/app.js'

document.addEventListener("turbo:load", function () {
  document.querySelectorAll("#sidebarnav a").forEach(function (link) {
    link.addEventListener("click", function (e) {
      const isActive = this.classList.contains("active");
      const parentUl = this.closest("ul");
      if (!isActive) {
        // hide any open menus and remove all other classes
        parentUl.querySelectorAll("ul").forEach(function (submenu) {
          submenu.classList.remove("in");
        });
        parentUl.querySelectorAll("a").forEach(function (navLink) {
          navLink.classList.remove("active");
        });

        // open our new menu and add the open class
        const submenu = this.nextElementSibling;
        if (submenu) {
          submenu.classList.add("in");
        }

        this.classList.add("active");
      } else {
        this.classList.remove("active");
        parentUl.classList.remove("active");
        const submenu = this.nextElementSibling;
        if (submenu) {
          submenu.classList.remove("in");
        }
      }
    });
  });

  function handleSidebar() {
    document.querySelectorAll(".sidebartoggler").forEach(function (element) {
      element.addEventListener("click", function () {
        document.querySelectorAll(".sidebartoggler").forEach(function (el) {
          el.checked = true;
        });
        document
          .getElementById("main-wrapper")
          .classList.toggle("show-sidebar");
        document.querySelectorAll(".sidebarmenu").forEach(function (el) {
          el.classList.toggle("close");
        });
        var dataTheme = document.body.getAttribute("data-sidebartype");
        if (dataTheme === "full") {
          document.body.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          document.body.setAttribute("data-sidebartype", "full");
        }
      });
    });
  }

  handleSidebar();


//****************************
// Theme Dark or light
//****************************
  document.querySelectorAll(".dark-layout").forEach((element) => {
    element.addEventListener("click", () =>
      document.documentElement.setAttribute("data-bs-theme", "dark")
    );
  });

  document.querySelectorAll(".light-layout").forEach((element) => {
    element.addEventListener("click", () =>
      document.documentElement.setAttribute("data-bs-theme", "light")
    );
  });
})
