import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    document.addEventListener("turbo:load", this.hidePreloader.bind(this))
    document.addEventListener("turbo:render", this.hidePreloader.bind(this))
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.hidePreloader)
    document.removeEventListener("turbo:render", this.hidePreloader)
  }

  showPreloader() {
    this.element.style.display = "block"
  }

  hidePreloader() {
    this.element.style.display = "none"
  }
}
