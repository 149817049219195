import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { initial: String }
  static targets = [ 'school', 'cut', 'amount', 'year', 'recovering']

  connect() {
    window.tooltipThem()
    this.years = this.initialValue.split('-');
  }

  set() {
    this.cut = this.myData[this.kind]
    this.amount = this.myData[this.kind + '_price']
  }

  year(e) {
    let current_year = e.target.dataset.year

    if ( this.years.length == 2 ) {
      this.years = [current_year]
    } else {
      this.years.push(current_year).sort
    }
    this.setYears()
  }

  setYears() {
    for( let i = 0; i < 5; i++ ) {
      let css = ['text-bg-secondary', 'text-bg-light']
      let css_index = 1
      let current_year = i + 1
      if ( current_year >= this.minYear && current_year <= this.maxYear ) {
        css_index = 0
      }

      this.yearTargets[i].classList.remove(...css)
      this.yearTargets[i].classList.add(css[css_index])
      this.recoveringTarget.value = `${this.minYear}-${this.maxYear}`
    }
  }

  get minYear() {
    return Math.min(...this.years);
  }

  get maxYear() {
    return Math.max(...this.years);
  }

  get cutAmount () {
    return this.unmask(this.myData[this.kind])
  }

  get myData() {
    return this.options[this.index].dataset
  }

  get options() {
    return this.schoolTarget.options
  }

  get index() {
    return this.schoolTarget.options.selectedIndex
  }

  get kind() {
    return this.cutTarget.dataset.kind
  }

  set cut(value) {
    this.cutTarget.value = value
  }

  get amount() {
    return this.unmask(this.amountTarget.value)
  }

  set amount(value) {
    this.amountTarget.value = value
  }
}
