import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="drawer"
export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element)
    this.show()
  }

  show() {
    this.modal.show()
  }
  
  hide() {
    this.modal.hide()
    this.element.innerHTML = ""
  }
}
