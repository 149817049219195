import { Calendar } from '@fullcalendar/core'
import timegridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import itLocale from '@fullcalendar/core/locales/it'

document.addEventListener("turbo:load", function () {
  let calendarEl = document.getElementById('dashboard-calendar')
  if (calendarEl !== null) {
    let calendar = new Calendar(calendarEl, {
      plugins: [
        bootstrapPlugin,
        timegridPlugin,
        listPlugin
      ],
      themeSystem: 'bootstrap',
      headerToolbar: {
        left: 'title',
        center: 'listDay,listWeek,listMonth',
        right: 'prev,today,next'
      },
      buttonText: {
        'listDay': 'Oggi',
        'listWeek': 'Settimana',
        'listMonth': 'Mese'
      },
      initialDate: new Date(),
      initialView: 'listDay',
      allDaySlot: false,
      navLinks: true,
      nowIndicator: true,
      firstDay: 1,
      businessHours: {
        daysOfWeek: [1, 2, 3, 4, 5],
        startTime: '08:00',
        endTime: '21:00',
      },
      slotMinTime: '08:00:00',
      slotMaxTime: '21:00:00',
      events: calendarEl.dataset.feed,
      locale: itLocale,
      eventDidMount: function (info) {
        let dotWrapper = info.el.getElementsByClassName('fc-list-event-graphic')[0];

        let icon = info.event.extendedProps.icon;
        let kind = info.event.extendedProps.kind;
        let wrapperClass = info.event.extendedProps.class;

        if (wrapperClass) {
          info.el.classList.add(wrapperClass);
        }

        if (dotWrapper) {
          if (kind) {
            dotWrapper.classList.add('p-2', `bg-${kind}`);
          }
          if (icon) {
            dotWrapper.innerHTML = `<i class='text-white fa ${icon}'></i>`;
          }
        }
      },
      eventContent: function (info) {
        let html = `<a href="${info.event.url}" data-turbo="false">${info.event.title}</a>`

        return { html: html };
      }
    });

    calendar.render();
  }
});
