// app/javascript/controllers/search_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.element.addEventListener('shown.bs.modal', () => {
      this.inputTarget.focus()
    })
  }
}